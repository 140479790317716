<template>

    <div class="main-wrapper">
        <NavbarDashboard></NavbarDashboard>
        <SidebarDashboard></SidebarDashboard>
        <div class="main-content">
            <section class="section">
                <div class="d-flex mt-3 ml-3  mb-2 align-items-center">
                    <ul class="nav nav-pills">
                        <li class="nav-item">
                            <router-link to="/data-client/detail" class="nav-link active">Karyawan</router-link>
                        </li>
                    </ul>
                </div>
                <div class="card">
                    <div class="row">
                        <div class="col-md-3 text-center" style="padding:0px">
                            <div class="col-left">
                                <div class="avatar">
                                    <img src="/img/avatar/ava.jpg" alt="">
                                </div>
                                <div class="name-employee">
                                    Max Verstappen
                                </div>
                                <div class="position">
                                    CEO
                                </div>
                                <ul class="list-menu">
                                    <li>
                                        <button class="menu">
                                            <router-link to="/data-karyawan/detail" class="nav-link ">Detail
                                            </router-link>
                                        </button>
                                    </li>
                                    <li>
                                        <button class="menu">
                                            <router-link to="/data-karyawan/histori" class="nav-link">Histori Jabatan
                                            </router-link>
                                        </button>
                                    </li>
                                    <li>
                                        <button class="menu">
                                            <router-link to="/data-karyawan/pajak" class="nav-link active">Pajak
                                            </router-link>
                                        </button>

                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-9" style="padding:0px">
                            <div class="col-right">
                                <div class="title-detail">
                                    <div class="title">Pajak Karyawan</div>
                                </div>
                                <hr>
                                <div class="info-detail ">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="title">Detail Informasi</div>
                                            <div class="sub-title">Informasi detail dari karyawan</div>
                                        </div>
                                       
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-md-3">
                                            <div class="text">
                                                NPWP
                                            </div>
                                            <div class="text">
                                                Status Pegawai
                                            </div>
                                            <div class="text">
                                                Status PTKP
                                            </div>
                                            <div class="text">
                                                Mulai
                                            </div>
                                            <div class="text">
                                                Status
                                            </div>

                                        </div>
                                        <div class="col-md-9">
                                            <div class="value">01.234.567.8-910.112</div>
                                            <div class="value">Tetap</div>
                                            <div class="value">TK (Tidak Kawin)</div>
                                            <div class="value">17-12-2019</div>
                                            <div class="value">
                                                <div class="red-bedge">
                                                    Nonaktif
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>


                            </div>


                        </div>
                    </div>

           

                </div>
            </section>
        </div>
    </div>
</template>





<script>
    import SidebarDashboard from '../../../components/Sidebar.vue'
    import NavbarDashboard from '../../../components/Navbar.vue'
    // import Select2 from 'vue3-select2-component';
    // import Datepicker from 'vue3-date-time-picker';



    import "datatables.net-dt/js/dataTables.dataTables"
    import "datatables.net-dt/css/jquery.dataTables.min.css"

    import $ from 'jquery'

    // import TableLite from 'vue3-table-lite'
    export default {
        name: 'dataDepartement',
        components: {
            // FooterDashboard,
            SidebarDashboard,
            NavbarDashboard,
            // Select2,
            // Datepicker
            // TableLite
        },

        data() {
            return {
                myValue: 'aaaa',
                statusCompany: ['Aktif', 'Tidak Aktif'], // or [{id: key, text: value}, {id: key, text: value}]
                optionPeriode: ['1 Bulan', '2 Bulan'],
                date: null,
                date2: null,

                placeholder: [{
                    text: 'kecamatan'
                }]
            }
        },

        mounted() {
            $('#tableDepartment').DataTable({
                info: false,
            });
        },
        methods: {
            myChangeEvent(val) {
                console.log(val);
            },
            mySelectEvent({
                id,
                text
            }) {
                console.log({
                    id,
                    text
                })
            },

            clickEdit() {
                $('.groupBtn-save-edit').removeClass("d-none")
                $('.groupBtn-detail').addClass("d-none")
                $('input').removeAttr('readonly')
                $('textarea').removeAttr('readonly')
                $('.edit-vendor ').removeClass("d-none")
                $('.input-none').addClass("d-none")
            },

            clickCancel() {
                $('input').attr('readonly')
                $('.groupBtn-detail').removeClass("d-none")
                $('.groupBtn-save-edit ').addClass("d-none")
                $('input').attr('readonly', true)
                $('.edit-vendor ').addClass("d-none")
                $('.input-none').removeClass("d-none")

            }
        },


    }
</script>


<style scoped src="../../../assets/css/style.css">
</style>


<style scoped src="../../../assets/css/custom.css">
</style>



<style scoped>
    .avatar img {
        width: 170px;
        height: 170px;
        border-radius: 50%;
        object-fit: cover;
    }

    .green-bedge {
        width: 100px;
         border-radius: 3px;
    }

    .red-bedge {
        width: 120px;
        border-radius: 3px;
    }



    .info-detail .title {
        font-size: 18px;
        font-weight: 500;
    }

    .info-detail .sub-title {
        color: #C6C6C6;
        font-size: 12px;
        font-weight: 400;
        margin-top: 10px;
    }


    .col-left {
        padding: 80px 0px;
        border: 1px solid #ECEFF1;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }

    .col-right {
        border: 1px solid #ECEFF1;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        border-left: none;
    }

    .title-detail {
        padding: 30px;
    }


    .text {
        margin-top: 20px;
        color: #000;
        font-size: 16px;
        font-weight: 500;
    }

    .value {
        margin-top: 20px;
        color: #000;
        font-size: 16px;
        font-weight: 400;
    }

    .info-detail {
        padding: 30px;
    }

    hr {
        margin: 0px;
        padding: 0px;
    }



    .row {
        /* border: 1px solid #ECEFF1; */
        border-radius: 10px;
    }

    .name-employee {
        font-size: 18px;
        font-weight: 600;
        margin-top: 1rem;
    }

    .position {
        color: #C6C6C6;
        font-size: 14px;
        margin-top: 0.5rem;
    }

    .menu .nav-link {
        color: #000;
    }

    .menu .nav-link.active {
        background: #68CAF8;
        color: #fff;
        border-radius: 5px;
        padding: 10px 30px;
    }

    .menu {
        border: none;
        background: none;
    }

    .list-menu {
        margin-top: 40px;
    }

    ul li {
        list-style: none;
        margin: 0px;
        padding: 0px;
    }

    ul {
        margin: 0px;
        padding: 0px;
    }


    .btn-save {
        background-color: #40DDD4;
        color: #fff;
        width: 90px;
        font-size: 16px;
        font-weight: 400;
        padding: 10px 0px;
    }

    .card .card-title {
        font-size: 18px;
        color: #000;
        font-weight: 500;
    }

    .card .nav-pills {
        margin: auto;
    }

    .card .nav .nav-item {
        margin-right: 30px;
    }

    .card {
        padding: 63px 50px;
        widows: 100%;
    }
</style>